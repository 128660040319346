import { useMemo } from 'react';
import Select from 'react-select';
import useSWR from 'swr';

import { type ModelsBgMusic } from '@lp-lib/api-service-client/public';

import { apiService } from '../../../../services/api-service';
import { Role } from '../../../../types';
import { buildReactSelectStyles } from '../../../../utils/react-select';
import { useUser } from '../../../UserContext';

export function useMusicLibrary() {
  return useSWR(
    '/shared-assets/music-library',
    async () => {
      const response = await apiService.training.getMusicLibrary();
      const data = response.data;
      return data.playlist
        .map((song) => {
          if (!song.mediaId || !song.media) return null;
          return {
            songId: song.id,
            songTitle: song.title,
            asset: {
              data: {
                id: song.mediaId,
              },
              media: song.media,
            },
            publiclyListed: song.publiclyListed,
          };
        })
        .filter((m) => !!m);
    },
    {
      dedupingInterval: 30000,
      keepPreviousData: true,
      shouldRetryOnError: false,
    }
  );
}

export function BlockMusicSelect(props: {
  value: Nullable<ModelsBgMusic | string>;
  onChange: (value: Nullable<ModelsBgMusic>) => void;
  className?: string;
}) {
  const user = useUser();
  const { data, isLoading } = useMusicLibrary();
  const styles = useMemo(() => buildReactSelectStyles<ModelsBgMusic>(), []);
  const options = useMemo(() => {
    if (user.role === Role.Admin) return data;
    return data?.filter((song) => song.publiclyListed) ?? [];
  }, [user.role, data]);
  const value = useMemo(() => {
    const songId =
      typeof props.value === 'string' ? props.value : props.value?.songId;
    return data?.find((song) => song.songId === songId);
  }, [props.value, data]);

  return (
    <Select<ModelsBgMusic, false>
      styles={styles}
      options={options}
      value={value}
      classNamePrefix='select-box-v2'
      className={`w-full text-white ${props.className}`}
      getOptionLabel={(option) => option.songTitle}
      getOptionValue={(option) => option.songId}
      onChange={props.onChange}
      isLoading={isLoading}
      isSearchable
      isClearable
      menuPlacement='auto'
      menuPosition='fixed'
    />
  );
}
